import Adjust from '@adjustcom/adjust-web-sdk';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './App.scss';
import AppLayout from './components/Layouts/AppLayout/AppLayout';
import AuthLayout from './components/Layouts/AuthLayout/AuthLayout';
import LandingLayout from './components/Layouts/LandingLayout/LandingLayout';
import PublicLayout from './components/Layouts/PublicLayout/PublicLayout';
import Loading from './components/Loading/Loading';
import ApiErrorModal from './components/Modals/ApiErrorModal/ApiErrorModal';
import { getUserId } from './core/services/adjust/adjust';
import { checkToken } from './core/services/app/checkToken/checkToken.slice';
import { setAppMounted } from './core/services/app/setAppMounted/setAppMounted.slice';
import { setPathname } from './core/services/app/setPathname/setPathname.slice';
import { setResponseInterceptor } from './core/services/axios';
import { setRemoteConfig } from './core/services/firebase/setRemoteConfig/setRemoteConfig.slice';
import { useAppDispatch, useAppSelector } from './core/services/hooks';
import Constants from './core/utilities/constants';
import Helpers from './core/utilities/helpers';
import { router } from './core/utilities/router';
import { LocalStorage, SessionStorage } from './core/utilities/storage';

declare global {
  interface Window {
    paymentFailed: any;
    paymentSuccess: any;
    openHome: any;
  }
}

const queryClient = new QueryClient();

function App() {
  const checkTokenState = useAppSelector((state) => state.app.checkToken);
  const isAppMounted = useAppSelector(
    (state) => state.app.setAppMounted.isMounted
  );
  const remoteConfigState = useAppSelector(
    (state) => state.firebase.setRemoteConfig
  );
  const me = useAppSelector((state) => state.app.setMe.me);
  const getUserInfoState = useAppSelector((state) => state.user.getUserInfo);

  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    Adjust.initSdk({
      appToken: `${process.env.REACT_APP_ADJUST_APP_TOKEN}`,
      environment:
        process.env.REACT_APP_ENV === 'production' ? 'production' : 'sandbox',
      externalDeviceId: getUserId(),
      logLevel: 'none',
    });
  }, []);

  useEffect(() => {
    const token = Helpers.getQueryParam(location, LocalStorage.token);
    if (token) {
      LocalStorage.set(LocalStorage.token, token);
    }
    setResponseInterceptor(dispatch);
    dispatch(setRemoteConfig());
    dispatch(checkToken());
    dispatch(setAppMounted(true));
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    const referalCode = Helpers.getQueryParam(
      location,
      Constants.urlReferalCode
    );
    if (referalCode) {
      SessionStorage.set(SessionStorage.referalCode, referalCode);
    }
    dispatch(setPathname(location.pathname));
    window.scrollTo(0, 0);
  }, [dispatch, location]);

  const isAppLoading =
    !isAppMounted ||
    remoteConfigState.loading ||
    checkTokenState.loading ||
    (!getUserInfoState.data && getUserInfoState.loading);

  const isAuthenticated = !!me;

  return (
    <div id="app">
      <Suspense fallback={<Loading className="app-loading" fontSize={48} />}>
        <QueryClientProvider client={queryClient}>
          {isAppLoading ? (
            <Loading className="app-loading" fontSize={48} />
          ) : (
            <Routes>
              <Route element={<LandingLayout />}>
                <Route path={router.LANDING} element={<LazyLandingPage />} />
              </Route>
              <Route element={<PublicLayout />}>
                <Route
                  path={router.CONTACTUS}
                  element={<LazyContactusPage />}
                />
                <Route path={router.FAQ} element={<LazyFaqPage />} />
              </Route>
              <Route
                path={router.PAYMENTSUCCESS}
                element={<LazyPaymentSuccessPage />}
              />
              <Route
                path={router.PAYMENTFAILED}
                element={<LazyPaymentFailedPage />}
              />
              {isAuthenticated ? (
                <Route element={<AppLayout />}>
                  <Route path={router.HOME} element={<LazyHomePage />} />
                  <Route
                    path={router.SUBSCRIPTION}
                    element={<LazySubscriptionPage />}
                  />
                  <Route
                    path={router.ANNOUNCEMENTS}
                    element={<LazyAnnouncementsPage />}
                  />
                  <Route
                    path={router.LEADERBOARD}
                    element={<LazyLeaderboardPage />}
                  />
                  <Route path={router.PROFILE} element={<LazyProfilePage />} />
                  <Route path={router.POLL} element={<LazyPollPage />} />
                  <Route path={router.WHEEL} element={<LazyWheelPage />} />
                  <Route
                    path={router.BENEFITS}
                    element={<LazyBenefitsPage />}
                  />
                  <Route path={router.INVITE} element={<LazyInvitePage />} />
                  <Route
                    path={router.PLUSPOINT}
                    element={<LazyPlusPointPage />}
                  />
                  <Route path={router.TOKEN} element={<LazyTokenPage />} />
                  <Route path={router.TRIVIA} element={<LazyTriviaPage />} />
                  <Route path="*" element={<Navigate to={router.HOME} />} />
                </Route>
              ) : (
                <Route element={<AuthLayout />}>
                  <Route path={router.LOGIN} element={<LazyLoginPage />} />
                  <Route
                    path={router.NICKNAME}
                    element={<LazyNicknamePage />}
                  />
                  <Route
                    path={router.CHOOSETEAM}
                    element={<LazyChooseTeamPage />}
                  />
                  <Route path="*" element={<Navigate to={router.LOGIN} />} />
                </Route>
              )}
            </Routes>
          )}
        </QueryClientProvider>
      </Suspense>
      <ApiErrorModal />
    </div>
  );
}

export default App;

const LazyHomePage = React.lazy(() => import('./pages/App/HomePage/HomePage'));
const LazyLandingPage = React.lazy(
  () => import('./pages/LandingPage/LandingPage')
);
const LazyLoginPage = React.lazy(
  () => import('./pages/Auth/LoginPage/LoginPage')
);
const LazyNicknamePage = React.lazy(
  () => import('./pages/Auth/NicknamePage/NicknamePage')
);
const LazyChooseTeamPage = React.lazy(
  () => import('./pages/Auth/ChooseTeamPage/ChooseTeamPage')
);
const LazySubscriptionPage = React.lazy(
  () => import('./pages/App/SubscriptionPage/SubscriptionPage')
);
const LazyAnnouncementsPage = React.lazy(
  () => import('./pages/App/AnnouncementsPage/AnnouncementsPage')
);
const LazyContactusPage = React.lazy(
  () => import('./pages/ContactUsPage/ContactUsPage')
);
const LazyFaqPage = React.lazy(() => import('./pages/FaqPage/FaqPage'));
const LazyLeaderboardPage = React.lazy(
  () => import('./pages/App/LeaderboardPage/LeaderboardPage')
);
const LazyProfilePage = React.lazy(
  () => import('./pages/App/ProfilePage/ProfilePage')
);
const LazyPollPage = React.lazy(() => import('./pages/App/PollPage/PollPage'));
const LazyWheelPage = React.lazy(
  () => import('./pages/App/WheelPage/WheelPage')
);
const LazyInvitePage = React.lazy(
  () => import('./pages/App/InvitePage/InvitePage')
);
const LazyPaymentSuccessPage = React.lazy(
  () => import('./pages/Payment/PaymentSuccessPage')
);
const LazyPaymentFailedPage = React.lazy(
  () => import('./pages/Payment/PaymentFailedPage')
);
const LazyBenefitsPage = React.lazy(
  () => import('./pages/App/BenefitsPage/BenefitsPage')
);
const LazyPlusPointPage = React.lazy(
  () => import('./pages/App/PlusPointPage/PlusPointPage')
);
const LazyTokenPage = React.lazy(
  () => import('./pages/App/TokenPage/TokenPage')
);
const LazyTriviaPage = React.lazy(
  () => import('./pages/App/TriviaPage/TriviaPage')
);
