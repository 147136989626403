import { ErrorDto, ParsedErrorDto } from '../models/dtos/error.dto';

export class Errors {
  public static readonly errorGenericTitle: string = 'Tüh!';
  public static readonly errorGenericText: string =
    'Beklenmeyen bir hata oluştu, lütfen tekrar dene.';

  public static readonly errorLoginQuickTitle: string = 'Çok hızlısın!';
  public static readonly errorLoginQuickText: string =
    'Aynı numara ile üst üste giriş tespit edildi, tekrar girmeden önce lütfen biraz bekle.';

  public static readonly messageBanned: string = 'banned-for-';
  public static readonly noActiveQuiz: string = 'no_active_quiz';
  public static readonly noMoreQuiz: string = 'no-more-quiz';
  public static readonly lackOfPoints: string = 'lack-of-points';
  public static readonly loginQuick: string = '-sec-valid-otp';
  public static readonly nicknameDuplicate: string = 'duplicate-nickname';
  public static readonly nicknameInappropriate: string =
    'inappropriate-nickname';
  public static readonly triviaInsufficient: string = 'no-coin';
  public static readonly triviaCampaignLimit: string = 'campaign-limit-reached';
  public static readonly triviaNoCampaign: string = 'no-active-campaign';
  public static readonly inviteAlready: string = 'already-invited';
  public static readonly codeNotValid = 'code-not-valid';

  private static readonly ErrorGeneric: ErrorDto = {
    status: 500,
    path: '',
    message: Errors.errorGenericText,
  };

  private static getParsedErrorDtoFromApiError = (
    error: unknown
  ): ParsedErrorDto => {
    return JSON.parse(JSON.stringify(error)) as ParsedErrorDto;
  };

  public static getErrorDtoFromApiError = (error: any): ErrorDto => {
    if (!error.response || !error.response.data) {
      return error;
    }
    return error.response.data;
  };

  public static isAuthError(error: unknown) {
    const { status } = this.getParsedErrorDtoFromApiError(error);
    return status === 401 || status === 403;
  }

  public static isLoginQuickError(error: unknown) {
    const { status, message } = this.getErrorDtoFromApiError(error);
    return status === 429 && message.includes(this.loginQuick);
  }

  public static isOtpError(error: unknown) {
    const { path } = this.getErrorDtoFromApiError(error);
    return path === '/login/otp/verify';
  }

  public static isChatBannedError(error: unknown) {
    const { status, message } = this.getErrorDtoFromApiError(error);
    return status === 400 && message.includes(this.messageBanned);
  }

  public static isNoActiveQuizError(error: unknown) {
    const { message } = this.getErrorDtoFromApiError(error);
    return message === this.noActiveQuiz;
  }

  public static isNoMoreQuizError(error: unknown) {
    const { message } = this.getErrorDtoFromApiError(error);
    return message === this.noMoreQuiz;
  }

  public static isInviteError(error: unknown) {
    const { path } = this.getErrorDtoFromApiError(error);
    return path === '/users/invite';
  }

  public static isInviteAlreadyError(error: unknown) {
    const { path, message } = this.getErrorDtoFromApiError(error);
    return path === '/users/invite' && message === this.inviteAlready;
  }

  public static isUpdateUserError(error: unknown) {
    const { path } = this.getErrorDtoFromApiError(error);
    return path === '/users';
  }

  public static isLackOfPointsError(error: unknown) {
    const { status, message } = this.getErrorDtoFromApiError(error);
    return status === 400 && message === this.lackOfPoints;
  }

  public static isNicknameDuplicateError(error: unknown) {
    const { status, message } = this.getErrorDtoFromApiError(error);
    return status === 400 && message === this.nicknameDuplicate;
  }

  public static isNicknameInappropriateError(error: unknown) {
    const { status, message } = this.getErrorDtoFromApiError(error);
    return status === 400 && message === this.nicknameInappropriate;
  }

  public static isTriviaInsufficientError(error: unknown) {
    const { status, message } = this.getErrorDtoFromApiError(error);
    return status === 400 && message === this.triviaInsufficient;
  }

  public static isTriviaCampaignLimitError(error: unknown) {
    const { status, message } = this.getErrorDtoFromApiError(error);
    return (
      status === 400 &&
      (message === this.triviaCampaignLimit ||
        message === this.triviaNoCampaign)
    );
  }

  public static isCampaignSubError(error: unknown) {
    const { status, message } = this.getErrorDtoFromApiError(error);
    return status === 400 && message === this.codeNotValid;
  }

  public static isErrorModalVisible(error: unknown) {
    return (
      !this.isOtpError(error) &&
      !this.isChatBannedError(error) &&
      !this.isNoActiveQuizError(error) &&
      !this.isNoMoreQuizError(error) &&
      !this.isInviteError(error) &&
      !this.isUpdateUserError(error) &&
      !this.isLackOfPointsError(error) &&
      !this.isNicknameDuplicateError(error) &&
      !this.isNicknameInappropriateError(error) &&
      !this.isTriviaInsufficientError(error) &&
      !this.isTriviaCampaignLimitError(error) &&
      !this.isCampaignSubError(error)
    );
  }
}
