import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { setApiError } from '../../../core/services/app/setApiError/setApiError.slice';
import { Errors } from '../../../core/services/errors';
import { useAppDispatch, useAppSelector } from '../../../core/services/hooks';
import AppButton from '../../Buttons/AppButton/AppButton';
import './ApiErrorModal.scss';

function ApiErrorModal() {
  const apiError = useAppSelector((state) => state.app.setApiError.error);
  const loginState = useAppSelector((state) => state.auth.login);

  const dispatch = useAppDispatch();

  const isOpen = (): boolean => {
    return !!apiError;
  };

  const closeModal = (): void => {
    dispatch(setApiError());
  };

  const getTitle = (): string => {
    if (loginState.error && apiError && Errors.isLoginQuickError(apiError)) {
      return Errors.errorLoginQuickTitle;
    }
    return Errors.errorGenericTitle;
  };

  const getText = (): string => {
    if (loginState.error && apiError && Errors.isLoginQuickError(apiError)) {
      return Errors.errorLoginQuickText;
    }
    return Errors.errorGenericText;
  };

  return (
    <Modal
      className="api-error-modal"
      open={isOpen()}
      closable={false}
      footer={null}
      centered
      destroyOnClose
      onCancel={closeModal}
    >
      <div className="content">
        <CloseCircleOutlined className="icon" />
        <h3 className="title">{getTitle()}</h3>
        <p className="text">{getText()}</p>
        <AppButton onClick={closeModal}>Tamam</AppButton>
      </div>
    </Modal>
  );
}

export default ApiErrorModal;
