import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from 'src/core/utilities/cn';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none cursor-pointer',
  {
    variants: {
      variant: {
        default:
          'bg-white text-[#111111] hover:bg-white/90 border-none disabled:opacity-50 rounded-xl',
        simple:
          'bg-white text-[#111111] hover:bg-white/90 border-none disabled:opacity-50 rounded-xl',
        lightblue:
          'bg-[#00C5FF] text-[#111111] hover:bg-[#00C5FF]/90 border-none disabled:opacity-50 rounded-md',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90 disabled:opacity-50',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground disabled:opacity-50',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80 disabled:opacity-50',
        ghost:
          'bg-transparent text-[#B1B1B1] border-none hover:bg-white/5 disabled:opacity-50',
        link: 'text-primary underline-offset-4 hover:underline disabled:opacity-50',
        'light-blue':
          'bg-[#0098FC33] text-[#0DC8FF] hover:bg-[#0098FC22] disabled:opacity-50 rounded-md border-none',
      },
      size: {
        default: 'h-9 px-4 py-2',
        xs: 'h-6 px-2 text-xs',
        sm: 'h-8 px-3',
        lg: 'h-10 px-8',

        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const CustomButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
CustomButton.displayName = 'CustomButton';

export { buttonVariants, CustomButton };
