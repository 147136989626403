import Adjust from '@adjustcom/adjust-web-sdk';
import { jwtDecode } from 'jwt-decode';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { LocalStorage } from '../../utilities/storage';

export const getUserId = () => {
  const token = LocalStorage.get(LocalStorage.token);
  if (!token) {
    return undefined;
  }
  const decodedJwt = jwtDecode(token);
  const userId =
    (decodedJwt &&
      typeof decodedJwt === 'object' &&
      'userId' in decodedJwt &&
      (decodedJwt.userId as string)) ||
    undefined;
  return userId;
};

export enum AdjustService {
  'otp_send_service_succesful' = 'psfq4c',
  'otp_verify_service_succesful' = '6r2q7i',
  'view_home_page' = '9dr244',
  'view_subscription_page' = 'mrigos',
  'payment_succesful' = 'q98jzp',
  'payment_failed' = 'l0oij2',
  'revenue' = '1nlu7o',
  'unique_web_page_open_count' = 'uzfs6b',
  'unique_otp_send_service_succesful' = '43dtkf',
  'unique_otp_verify_service_succesful' = 'sqmtlm',
  'unique_view_home_page' = 'ot7krp',
  'unique_view_subscription_page' = '22t99i',
  'unique_payment_succesful' = 'ds9bc5',
  'unique_payment_failed' = 'dbkyv4',
}

function trackEvent(eventId: string, uniqueId?: string) {
  let callbackParams = [];
  const userId = getUserId();
  callbackParams.push({ key: 'user_id', value: userId?.toString() });

  Adjust.trackEvent({
    eventToken: eventId,
    deduplicationId: uniqueId,
    // callbackParams,
  });
}
function revenueEvent(
  eventId: string,
  revenue: number,
  currency: string,
  uniqueId?: string
) {
  let callbackParams = [];
  const userId = getUserId();
  callbackParams.push({ key: 'user_id', value: userId?.toString() });

  Adjust.trackEvent({
    eventToken: eventId,
    deduplicationId: uniqueId,
    revenue,
    currency,
    // callbackParams,
  });
}

export const useAdjustEventOnMount = (
  eventKey: AdjustService,
  unique?: boolean
) => {
  const [uniqueId] = useState(nanoid());
  useEffect(() => {
    !unique && trackEvent(eventKey);
    unique && trackEvent(eventKey, uniqueId);
  }, [eventKey, uniqueId, unique]);
};

export const useAdjustEvent = (eventKey: AdjustService, unique?: boolean) => {
  const [uniqueId] = useState(nanoid());
  const trigger = () => {
    !unique && trackEvent(eventKey);
    unique && trackEvent(eventKey, uniqueId);
  };
  return trigger;
};

export const useAdjustRevenue = () => {
  const trigger = (revenue: number, currency = 'TRY') => {
    revenueEvent(AdjustService.revenue, revenue, currency);
  };
  return trigger;
};
