import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QuizRequest } from 'src/core/models/requests/quiz.request';
import answerQuizRequest from './answerQuiz/answerQuiz.repository';
import finishQuizRequest from './finishQuiz/finishQuiz.repository';
import getActiveQuizRequest from './getActiveQuiz/getActiveQuiz.repository';
import nextQuizRequest from './nextQuiz/nextQuiz.repository';
import startQuizRequest from './startQuiz/startQuiz.repository';

export const useActiveQuiz = (enabled = true) => {
  return useQuery({
    queryKey: ['activeQuiz'],
    queryFn: () => getActiveQuizRequest(),
    enabled,
    retry: false,
    staleTime: 1000,
  });
};

export const useNextQuiz = (enabled = true) => {
  return useQuery({
    queryKey: ['nextQuiz'],
    queryFn: () => nextQuizRequest(),
    enabled,
    retry: false,
  });
};
export const useStartQuiz = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['startQuiz'],
    mutationFn: (data: { quizId: number }) => startQuizRequest(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['activeQuiz'] });
    },
  });
};

export const useStartNextQuiz = () => {
  const { refetch: fetchNextQuiz } = useNextQuiz(false);
  const { mutate: startQuiz } = useStartQuiz();
  return useMutation({
    mutationKey: ['startNextQuiz'],
    mutationFn: async () => {
      const nextFetch = await fetchNextQuiz();
      if (nextFetch.data) {
        return startQuiz({ quizId: nextFetch.data });
      }
    },
  });
};
export const useFinishQuiz = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['finishQuiz'],
    mutationFn: () => finishQuizRequest(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['activeQuiz'] });
      queryClient.invalidateQueries({ queryKey: ['nextQuiz'] });
    },
  });
};

export const useAnswerQuiz = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['answerQuiz'],
    mutationFn: (data: QuizRequest) => answerQuizRequest(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['activeQuiz'] });
    },
  });
};
